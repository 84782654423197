import {
  SUPPLIER_ATTACH_INVOICE_ROUTE,
  COMPLETE_CONFIRM_SATISFACTION_ROUTE,
  SUBCONTRACTOR_AGREEMENT_ROUTE,
  DOCUMENTS_REQUEST_ROUTE,
  ADD_SERVICE_TICKET_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  ADMIN_SETUP_2FA_PAGE_PATH,
  VENDOR_INFO_PAGE_ROUTE,
  ADMIN_PO_PATH,
  REQUESTOR_SUBMIT_SURVEY_ROUTE,
  WOC_ASSETS_DOWNLOAD_PATH,
} from '@poly/constants';

export const routesNames = {
  LOGIN: '/login',
  RESET_PASSWORD: '/set-password/:token',
  HOME: '/',
  FORGOT_PASSWORD: FORGOT_PASSWORD_ROUTE,
  CLIENTS: '/clients',
  PROSPECTS: '/prospects',
  ACCOUNT_INDEX: '/account-index',
  SUPPLIERS_ADMINISTRATIVE_AAC: '/suppliers-administrative-aac',
  SUPPLIERS_SUBCONTRACTORS: '/suppliers-subcontractors',
  MASTER_SUPPLIERS_ADMINISTRATIVE: '/master-suppliers-administrative',
  MASTER_SUPPLIERS_SUBCONTRACTORS: '/master-suppliers-subcontractors',
  SUPPLIER: '/supplier/:supplierId',
  MASTER_SUPPLIER: '/master-supplier/:supplierId',
  ADD_SUPPLIER: '/add-supplier',
  ADD_BRANCH_SUPPLIER: '/add-branch-supplier',
  ADD_MASTER_SUPPLIER: '/add-master-supplier',
  MY_PROJECTS: '/my-projects',
  PROJECTS_DIRECTORY: '/projects-directory',
  UNASSIGNED_PROJECTS: '/unassigned-projects',
  MY_TASKS: '/my-tasks',
  STAFF_DIRECTORY: '/staff-directory',
  ADMIN_SETTINGS: '/admin-settings',
  GENERATE_ASSET_QR_CODES: '/generate-asset-qr-codes',
  REPORTS: '/reports',
  MENTION_GROUPS: '/mention-groups',
  FINANCIAL_DIRECTORY: '/financial',
  NOT_FOUND: '/*',
  RECENT_PROJECTS_REPORT: '/recent-projects-report',
  SUPPLIER_SEARCH: '/supplier-search',
  STAFF_ACTIVITY_REPORT: '/staff-activity-report',
  STAFF_ACTIVITY_REPORT_DETAILS:
    '/staff-activity-details/:userId/:type/:startDate/:endDate',
  TIME_SHEET: '/time-sheet',
  TIME_SHEET_REPORT: '/time-sheet-report',
  TIME_SHEET_REPORTS: '/time-sheet-reports',
  SERVICE_TYPES: '/service-types',
  ASSET_MANUFACTURERS: 'asset-manufacturers',
  ASSET_TYPES: '/asset-types',
  MASTER_PROJECTS_DIRECTORY: '/master-projects-directory',
  MY_MASTER_PROJECTS_DIRECTORY: '/my-master-projects-directory',
  EMERGENCY_PROJECTS: '/emergency-projects',
  MY_EMERGENCY_PROJECTS: '/my-emergency-projects',
  RECALLED_PROJECTS: '/recalled-projects',
  SUPPLIERS_MAP: '/suppliers-map',
  SPEND_REPORTS: '/spend-report',
  QUOTE_DISCREPANCIES: '/quote-discrepancies',
  PAY_SUPPLIERS: '/pay-suppliers',
  ENTER_SUPPLIER_INVOICE: '/enter-supplier-invoice',
  PRINT_CHECKS: '/print-checks',
  PRINT_CHECKS_DETAILS: '/print-checks-details',
  SETTINGS: '/settings',
  SUPPLIER_PAYMENT_HISTORY: '/supplier-payment-history',
  SEARCH_SUPPLIER_INVOICES: '/search-supplier-invoices',
  EDIT_SUPPLIER_INVOICE: '/edit-supplier-invoice/:invoiceId',
  ACCOUNT_TYPES: '/account-types',
  DIVISIONS: '/divisions',
  CHART_OF_ACCOUNTS: '/chart-of-accounts',
  GENERAL_LEDGER: '/general-ledger',
  VOID_SUPPLIER_PAYMENTS: '/void-supplier-payments',
  ACCOUNT_TRANSACTIONS: '/account-transactions',
  BANK_REGISTERS: '/bank-registers',
  TRANSACTION_DETAILS: '/transaction-details/:transactionNumber',
  CREDIT_CARD_REGISTERS: '/credit-card-registers',
  BANK_RECONCILIATION: '/bank-reconciliation',
  BANK_RECONCILIATION_REPORT: '/bank-reconciliation-report',
  BANK_DEPOSITS: '/bank-deposits',
  HOUSEKEEPING_BATCH_INVOICING: '/housekeeping-batch-invoicing',
  BASS_PRO_TM_BATCH_INVOICING: '/bass-pro-tm-invoicing',
  BASS_PRO_BID_BATCH_INVOICING: '/bass-pro-bid-invoicing',
  BFS_DORMANT_BATCH_INVOICING: '/bfs-dormant-invoicing',
  BFS_ACTIVE_BATCH_INVOICING: '/bfs-active-invoicing',
  UMC_BATCH_INVOICING: '/umc-batch-invoicing',
  REGIONAL_MANAGEMENT_JANITORIAL_BATCH_INVOICING:
    '/regional-management-janitorial-batch-invoicing',
  REGIONAL_MANAGEMENT_BATCH_INVOICING: '/regional-management-batch-invoicing',
  CREATE_JOURNAL_ENTRY: '/create-journal-entry',
  JOURNAL_ENTRY_LEDGER: '/journal-entry-ledger',
  ACCOUNTS_PAYABLE_REPORT: '/accounts-payable-report',
  ACCOUNTS_RECEIVABLE_REPORT: '/accounts-receivable-report',
  CLIENT_INVOICE_HISTORY: '/client-invoice-history',
  HOUSEKEEPING_SERVICE_TICKETS_REPORT: '/housekeeping-service-tickets-report',
  BATCH_INVOICE_REPORTS: '/batch-invoice-reports',
  BATCH_INVOICE_REPORTS_DETAILS: '/batch-invoice-reports-details/:batchId',
  PAY_CLIENT_INVOICES: '/receive-client-invoices',
  AP_AGING_REPORT: '/ap-aging-report',
  AR_AGING_REPORT: '/ar-aging-report',
  CLIENT_HISTORY: '/client-history',
  EDIT_CLIENT_PAYMENTS: '/edit-client-payments',
  WORK_IN_PROGRESS_REPORT: '/work-in-progress',
  CREDIT_CARD_RECONCILIATION: '/credit-card-reconciliation',
  CREDIT_CARD_RECONCILIATION_REPORT: '/credit-card-reconciliation-report',
  SUPPLIER_AP_REPORT: '/supplier-ap-report',
  MASTER_SUPPLIER_AP_REPORT: '/master-supplier-ap-report',
  BALANCE_SHEET: '/balance-sheet',
  PROFIT_LOSS_REPORT: '/profit-lost-report',
  SEARCH_TRANSACTIONS: '/search-transactions',
  TRANSFER_FUNDS: '/transfer-funds',
  CREDIT_CARD_USERS: '/credit-card-users',
  TRIAL_BALANCE: '/trial-balance',
  PAY_BY_BANK: '/pay-by-bank',
  PAY_BY_CREDIT_CARD: '/pay-by-credit-card',
  INPUT_PAYROLL: '/input-payroll',
  SEARCH_CLIENT_INVOICES: '/search-edit-client-invoice',
  SEARCH_VOID_CLIENT_INVOICES: '/search-void-client-invoice',
  CREATE_CLIENT_INVOICE_SEARCH: '/create-client-invoice-search',
  CREATE_CLIENT_INVOICE: '/create-client-invoice',
  EDIT_CLIENT_INVOICE: '/edit-client-invoice/:invoiceId',
  SUPPLIER_ATTACH_SERVICE_TICKET_FILE: `${ADD_SERVICE_TICKET_ROUTE}/:randomId`,
  CLOSE_FINANCIAL_YEAR: '/close-financial-year',
  POST_CLOSING_JOURNAL: '/post-closing-journal',
  REOPEN_FINANCIAL_YEAR: '/reopen-financial-year',
  PRINT_CLIENT_INVOICE: '/print-client-invoice',
  SUPPLIER_1099_REPORT: '/supplier-1099-report',
  SUPPLIER_ATTACH_INVOICE_FILE: `${SUPPLIER_ATTACH_INVOICE_ROUTE}/:randomId`,
  COMPLETE_CONFIRM_SATISFACTION: `${COMPLETE_CONFIRM_SATISFACTION_ROUTE}/:randomId`,
  OPEN_SUPPLIER_INVOICE: '/open-supplier-invoice',
  WISCONSIN_TAX_REPORT: '/wisconsin-tax-report',
  NEW_YORK_TAX_REPORT: '/new-york-tax-report',
  SALES_TAX_REPORT_BY_STATE: '/sales-tax-report-by-state',
  CLIENT_SALES_REPORT: '/client-sales-report',
  CLIENT_INVOICING: '/client-invoicing',
  RECURRING_JOURNALS: '/recurring-journals',
  EDIT_RECURRING_JOURNAL: '/edit-recurring-journal/:journalId',
  VENDOR_LEDGER_REPORT: '/vendor-ledger-report/:vendorId',
  BATCH_GENERATION_QUEUE: '/batch-generation-queue',
  COMPLETED_BATCHES: '/completed-batches',
  SETUP_2FA: ADMIN_SETUP_2FA_PAGE_PATH,
  WORK_IN_PROGRESS_QC_REPORT: '/work-in-progress-qc',
  SUPPLIER_DOCUMENT_EXCEPTIONS: '/supplier-document-exceptions',
  APPROVE_SUPPLIER_INVOICES: '/approve-supplier-invoices',
  DAILY_SUPPLIER_INVOICE_BATCH: '/daily-supplier-invoices-batch',
  PROJECT_CARD: '/project-card/:projectId',
  MASTER_PROJECT_CARD: '/master-project-card/:projectId',
  PROPERTY_CARD: '/property/:propertyId',
  CLIENT_CARD: '/client/:clientId',
  PROPERTY_HIERARCHY_MANAGEMENT: '/property-hierarchy-management',
  CLIENT_GL_CODES: '/client-gl-codes/:clientId',
  MONDELEZ_INVOICING: '/mondelez-invoicing',
  CLIENT_INVOICING_COLLECTION: '/client-invoicing-collection',
  ASSET_MANUFACTURER_MODELS: '/asset-manufacture-models/:manufacturerId',
  NEW_SUPPLIER_DOCUMENTS_REVIEW: '/new-supplier-documents-review',
  DOCUMENTS_REQUEST: DOCUMENTS_REQUEST_ROUTE,
  INCOME_STATEMENT_REPORT: 'income-statement-report',
  ASSET_REVIEW: '/asset-review',
  VENDOR_INFO: VENDOR_INFO_PAGE_ROUTE,
  SUBCONTRACTOR_AGREEMENT: SUBCONTRACTOR_AGREEMENT_ROUTE,
  PURCHASE_ORDERS: ADMIN_PO_PATH,
  MY_RECURRING_PROJECTS_DIRECTORY: '/my-recurring-projects-directory',
  LOCK_PREVIOUS_MONTH: '/lock-previous-month',
  USER_GROUP_MANAGEMENT: 'user-group-management',
  REQUESTOR_SUBMIT_SURVEY: `${REQUESTOR_SUBMIT_SURVEY_ROUTE}/:randomId`,
  OPEN_WORK_ORDERS_REPORT: '/open-work-orders-report',
  MANAGE_PROCEDURES: '/manage-procedures',
  SUPPLIERS_QUOTE_DISCREPANCIES: '/suppliers-quote-discrepancies',
  TASK_TEMPLATES: '/task-templates',
  WOC_ASSETS_DOWNLOAD: `${WOC_ASSETS_DOWNLOAD_PATH}/:randomId`,
  CONFIRM_SATISFACTION_REPORT: '/confirm-satisfaction-report',
  SUB_CATEGORY_REPORT: '/sub-category-report',
  CLIENT_SURVEYS_SUMMARY_REPORT: '/client-surveys-summary-report',
  DETAILED_CLIENT_SURVEYS_REPORT: '/detailed-client-surveys-report',
};

export const authRoutesList = [
  routesNames.LOGIN,
  routesNames.FORGOT_PASSWORD,
  routesNames.RESET_PASSWORD,
];

export const supplierPageTabs = {
  supplierAccount: 'supplier-account',
  supplierProjects: 'supplier-projects',
  supplierRecurring: 'supplier-recurring',
  supplierUpdates: 'supplier-updates',
  supplierTasks: 'supplier-tasks',
  supplierPeople: 'supplier-people',
  supplierFiles: 'supplier-files',
  supplierInvoices: 'supplier-invoices',
};

export const clientSidebarTabs = {
  clientMasterProperties: 'client-master-properties',
  clientProperties: 'client-properties',
  clientProjects: 'client-projects',
  clientMasters: 'client-masters',
  clientTasks: 'client-tasks',
  clientUpdates: 'client-updates',
  clientContacts: 'client-contacts',
  clientFiles: 'client-files',
  clientChildAccounts: 'child-accounts',
  clientPortalUsers: 'client-portal-users',
};

export const staffSidebarTabs = {
  staffUpdates: 'staff-updates',
  staffFiles: 'staff-files',
};

export const propertySidebarTabs = {
  propertyChildProperties: 'property-child-properties',
  propertyProjects: 'property-projects',
  propertyMasters: 'property-masters',
  propertyTasks: 'property-tasks',
  propertyUpdates: 'property-updates',
  propertyContacts: 'property-contacts',
  propertyFiles: 'property-files',
  propertySuppliers: 'property-suppliers',
  propertyAssets: 'property-assets',
  propertyLocationHierarchy: 'property-location-hierarchy',
};

export const projectSidebarTabs = {
  projectTasks: 'project-tasks',
  projectUpdates: 'project-updates',
  projectSuppliers: 'project-suppliers',
  projectFiles: 'project-files',
  projectInvoices: 'project-invoices',
  projectAssets: 'project-assets',
};

export const masterProjectSidebarTabs = {
  masterProjectChild: 'master-project-child',
  masterProjectUpdates: 'master-project-updates',
  masterProjectTasks: 'master-project-tasks',
  masterProjectFiles: 'master-project-files',
  masterProjectAssets: 'master-project-assets',
};

export const recentProjectsPageTabs = {
  recentProjectsCompleted: 'recent-projects-completed',
  recentProjectsActive: 'recent-projects-active',
};

export const masterSupplierPageTabs = {
  masterSupplierAccount: 'master-supplier-account',
  masterSupplierBranches: 'master-supplier-branches',
  masterSupplierProjects: 'master-supplier-projects',
  masterSupplierRecurring: 'master-supplier-recurring',
  masterSupplierUpdates: 'master-supplier-updates',
  masterSupplierTasks: 'master-supplier-tasks',
  masterSupplierPeople: 'master-supplier-people',
  masterSupplierFiles: 'master-supplier-files',
  masterSupplierInvoices: 'master-supplier-invoices',
};

export const userAlertsPopupTabs = {
  userAlertsPopupActive: 'alerts-active',
  userAlertsPopupArchived: 'alerts-archived',
};

export const assetSidebarTabs = {
  assetProjects: 'asset-projects',
  assetPMs: 'asset-pms',
  assetUpdates: 'asset-updates',
  assetFiles: 'asset-files',
  assetProcedures: 'asset-procedures',
};

export const purchaseOrderSidebarTabs = {
  purchaseOrderUpdates: 'purchase-order-updates',
  purchaseOrderContacts: 'purchase-order-contacts',
  purchaseOrderFiles: 'purchase-order-files',
  purchaseOrderTasks: 'purchase-order-tasks',
  purchaseMastersProjects: 'purchase-master-projects',
  purchaseProjects: 'purchase-projects',
};

export const userGroupSidebarTabs = {
  userGroupUsers: 'user-group-users',
  userGroupUpdates: 'user-group-updates',
};
